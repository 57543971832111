<template>
  <div v-if="!isPreloader">
    <base-table
        v-if="shownData.length"
        :columns="referencesFields"
        :rows="shownData"
        @sort="(data) => {
        sortValue = data.sortValue;
        sortDirection = data.direction;
      }"
    />

    <table-pagination
        v-if="shownData.length"
        :key="updatePagination"
        :totalPages="1"
        :countOnPage="countOnPage"
        :total="rowData.length"
        :count="countOnPage > shownData.length ? shownData.length : countOnPage"
        @changePage="changePage"
    />

    <div v-else class="no_results">
      <p>Нет результатов</p>
    </div>
  </div>
  <div v-else class="table-preloader">
    <preloader/>
  </div>
</template>

<script>
import VPagination from "@hennge/vue3-pagination";
import {ref} from "@vue/reactivity";
import {useStore} from "vuex";
import {computed, onBeforeMount, watch} from "@vue/runtime-core";
import Preloader from "@/components/Technical/Preloader.vue";
import TablePagination from "@/components/OtherElements/TablePagination.vue";
import useCheckRights from '@/mixins/useCheckRights';
import getRusData from '@/mixins/rusData';
import BaseTable from "@/components/Tables/BaseTable.vue";
import sortData from "@/mixins/sortData";

export default {
  name: "references-table",
  components: {
    BaseTable,
    VPagination,
    Preloader,
    TablePagination,
  },

  setup(props, context) {
    const store = useStore(),
        {getRight} = useCheckRights();

    const countOnPage = ref(15),
        isPreloader = ref(false);

    const shownData = computed(() => {

      let data = rowData.value ?? [];

      if (data.length) {

        if (sortValue.value) {
          data = sortData(data, sortValue.value, sortDirection.value);
        } else {
          data = sortData(data, 'id', 'down');
        }

        data = data.slice(pageDataStart.value, pageDataStart.value + countOnPage.value);
      }

      return data;
    });

    const pageDataStart = ref(0);
    const sortValue = ref('');
    const sortDirection = ref('');
    const updatePagination = ref(false);

    const changePage = (page) => {
      pageDataStart.value = (page - 1) * countOnPage.value;
    };

    onBeforeMount(() => {
      isPreloader.value = true;
      store.dispatch("curator/getCurators");
      store.dispatch("analytics/getReferenceProfiles").then(() => isPreloader.value = false);
    });

    const referenceProfiles = computed(() => {
      return store.state.analytics.referenceProfiles
    });

    const profiles = ref(referenceProfiles),
        rowData = computed(() => {
          return profiles.value.map((passing) => {
            return {
              id: passing.id,
              title: passing.title,
              curator: passing.curator,
              calculated: passing.calculated !== 0 ? 'рассчитан' : 'не рассчитан',
              updated_at: getRusData(passing.updated_at)
            };
          });
        });

    const onCellClicked = (id) => {
      openReference(id, true);
    }
    const openReference = (id, readOnly) => {
      context.emit("openReference", {id, readOnly});
    }

    const getSelectValue = ($event, rowIndex) => {
      const curatorId = $event.id !== 'Не выбран' ? $event.id : null;
      const passingProfile = rowData.value[rowIndex]

      let profile = profiles.value.filter((profile) => {
        return profile.id == passingProfile.id
      });

      const newProfile = JSON.parse(JSON.stringify(profile[0]))

      newProfile.curator_id = curatorId

      newProfile.company_id = store.state.profile.profile.company.id

      store.dispatch("analytics/updateReferenceProfile", newProfile)
    };

    const referencesFields = [
      {
        headerName: 'Название',
        field: "title",
        sortable: true,
        actionCallback: onCellClicked,
      },
      {
        headerName: 'Статус',
        field: "calculated",
        sortable: true,
      },
      {
        headerName: '',
        field: 'save_report',
        text: 'Скачать отчёт',
        type: 'report',
      },
      {
        headerName: '',
        field: 'save_report',
        text: 'Аналитика',
        type: 'analytics',
      },
      {
        headerName: '',
        field: 'delete_reference',
      },
    ];

    const defaultColDef = {
      suppressMovable: true,
      wrapText: true,
      autoHeight: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      floatingFilter: true,
      resizable: true,
      sortable: true,
    };

    watch(
        () => [sortDirection.value, sortValue.value],
        () => {
          pageDataStart.value = 0;
          updatePagination.value = !updatePagination.value;
        },
    )

    return {
      countOnPage,
      isPreloader,
      profiles,
      rowData,
      defaultColDef,
      getRusData,
      getRight,
      openReference,

      shownData,
      sortValue,
      sortDirection,
      referencesFields,
      pageDataStart,
      changePage,
      updatePagination
    };
  },
};
</script>

<style lang="scss" scoped>
.filters {
  padding: 10px 8px;
}

.no_results {
  padding: 40px 10px;
}
</style>
